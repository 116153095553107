import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient, private toastr: ToastrService) {}

  public post<T>(
    url: string,
    data?: any,
    time: number = 100000
  ): Observable<T> {
    return new Observable((observer) => {
      let token = localStorage.getItem('Token');

      let httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      });
      if (token) {
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + token);
      }

      let options = {
        headers: httpHeaders,
      };

      this.http
        .post<T>(url, data, options)
        .pipe(timeout(time))
        .subscribe(
          (result) => {
            observer.next(result);
            observer.complete();
          },
          (err: HttpErrorResponse) => {
            //Exception Handling

            if (err.status == 200) {
              //when Response is not application/json ex: some API return text/csv
              observer.next(err.error.text);
              observer.complete();
            } else {
              let unauthorized = this.unauthorizedErrorHandling(err);
              if (!unauthorized) {
                let errMessage = this.errorMessageHandling(err);
                this.toastr.error(
                  errMessage.message != ''
                    ? errMessage.message
                    : 'An unknown error has occured!',
                  errMessage.header
                );
              }

              observer.error(err);
            }
          }
        );
    });
  }

  private unauthorizedErrorHandling(err: HttpErrorResponse) {
    let retVal = false;
    if (err.status == 401) {
      //Unauthorized Error Handling
      let token = localStorage.getItem('Token');
      if (token) {
        window.location.href = '/A/Unauthorized/Main/Unauthorized';
      } else {
        //redirect to Login page if there is no JWT TOKEN available
        window.location.href = '/';
      }
      retVal = true;
    }
    return retVal;
  }

  private errorMessageHandling(err: HttpErrorResponse): {
    message: string;
    header: string;
  } {
    let header = 'API Error';
    let message = 'Exception Message';

    if (err.error) {
      //Handle 4XX error and 5XX Error
      header = 'API Error - ' + err.status;
      if (err.error.Message) {
        message = err.error.Message;
      }
    } else {
      if (err.message) {
        //Handle timeout scenario we do not receive HTTP StatusCode
        header = 'API Error - ' + err.name;
        message = err.message;
      }
    }
    return { header: header, message: message };
  }
}
