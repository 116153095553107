<div class="container">
    <div class="row mt-5 mt-md-0 align-self-center">	
      <div class=" col-12 col-md-7 text-center">
        <h1 class="big-display my-5 py-5 robotoslab d-none d-md-block">Kitchen Display</h1>
          <img src="https://res.cloudinary.com/pos/image/upload/v1618804208/Apps/Login/Group_120.png" alt="integration-image" class="img-fluid img-icon mt-5 pt-5">
      </div>
      <div class="col-12 col-md-5 order-first order-md-last">
        <h1 class="big-display text-center my-5 py-5 robotoslab d-block d-md-none">Kitchen Display</h1>
        <div class="card w-100">
          <div class="card-body py-10">
            <div class="card-title">
              <h2 class="ms-2 fw-normal font-xl robotoslab mb-10">Sign in</h2>
            </div>
            <form class="">       
              <div class="form-label-group">
                <input #domainAutoFocus type="text" [(ngModel)]="posDomain" name="inputPOSDomain" id="inputPOSDomain" class="form-control text-start"
                       placeholder="POS Domain (xyz.dealpos.com)" required pattern="(https:\/\/)?\w+.dealpos(.com|.net|.co.id)(\/)?" #domain="ngModel">
                <label for="inputPOSDomain" class="text-start">POS Domain (xyz.dealpos.com)</label>
              </div>
              <div class="form-label-group">
                <input type="text" [(ngModel)]="username" name="username" id="inputUsername" class="form-control text-start"
                       placeholder="Username" required>
                <label for="inputUsername" class="text-start">Username</label>
              </div>
              <div class="form-label-group mb-5 mb-md-2">
                <input #passAutoFocus [type]="passwordType" [(ngModel)]="password" name="something" id="inputPassword" class="form-control text-start"
                  placeholder="Password" required>
                <label for="inputPassword" class="text-start">Password</label>
                <i *ngIf="password != ''" [ngClass]="{'fa-eye-slash': (passwordIcon == 'eye') , 'fa-eye' : (passwordIcon == 'eye-off') }" class="passwordIcon fas fa-lg" (click)='hideShowPassword()'></i>
              </div>
              <div class="row justify-content-between align-items-center mt-4">
                <div class="col-12 col-lg-5 order-first order-md-last">
                  <button class="btn btn-lg btn-primary btn-block font125rem robotoslab" type="submit" (click)="login()">Log in</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 d-block d-md-none text-center">
        <p class="pt-1rem me-3">Powered by : </p>
        <a target="_blank" href="https://www.dealpos.com"><img src="https://res.cloudinary.com/pos/image/upload/POS/Images/Login/dealpos.png" alt="dealpos-logo" class="img-fluid"></a>
        <a href="https://changelog.dealpos.com/?categories=cat_kWMC17NsuWPeP&view=complete" target="_blank"><p class="pt-1rem fst-italic">Version 1.2.0</p></a>
      </div>
    </div>

  </div>
  <footer class="mx-5 pb-5 mb-5 d-none d-md-block">
    <div class="row justify-content-between align-items-center">
      <div class="col-auto d-inline-flex">
        <p class="pt-1rem me-3">Powered by : </p>
        <a target="_blank" href="https://www.dealpos.com"><img src="https://res.cloudinary.com/pos/image/upload/POS/Images/Login/dealpos.png" alt="dealpos-logo" class="img-fluid"></a>
      </div>
      <div class="col-auto">
        <a href="https://changelog.dealpos.com/?categories=cat_kWMC17NsuWPeP&view=complete" target="_blank"><p class="pt-1rem fst-italic">Version 1.2.0</p></a>
      </div>
    </div>
  </footer>
