import { HeaderType } from "../../enums/header-type.enum";

export class ModalMaterialConfig {
    title = "New Modal";
    titleExtra = "";
    okText= "Ok (F2)";
    cancelText = "Cancel";
    hideFooter = false;
    hideCancel = false;
    hideOk = false;
    hideClose = false;
    eMoney = false;
    headerType = HeaderType.Success;
    validated = true;

    customWidth = 'initial';

    bodyScrollable = false;
    hideScrollbar = false;
    disableKeyup? = false;
    hideMoreButton = false;
}
