<div class="toast-case">
    <div class="row toast-header" *ngIf="title" [style.display]="state.value === 'inactive' ? 'none' : ''">
        <div class="col-9 px-0">
            <div [class]="options.titleClass" [attr.aria-label]="title">
                <h4>{{title}}</h4>
            </div>
        </div>
        <div class="col-auto mt-5 pe-0">
            <a *ngIf="options.closeButton" (click)="remove()" class="btn toast-close-button btn-sm">
                x
            </a>
        </div>
    </div>
    <div class="row toast-body" [ngClass]="{'notitle': title == null}" *ngIf="message && options.enableHtml"
        role="alert" aria-live="polite" [class]="options.messageClass" [innerHTML]="message"></div>
    <div class="row toast-body" [ngClass]="{'notitle': title == null}" *ngIf="message && !options.enableHtml"
        role="alert" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
    </div>
    <a *ngIf="options.closeButton && (title == null)" (click)="remove()" class="btn toast-close-button btn-sm ">
        x
    </a>
    <div *ngIf="options.progressBar">
        <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
</div>