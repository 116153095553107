import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import jwt_decode from "jwt-decode";
import { HttpService } from '../services/http-service/http.service';
import { AuthenticationService } from '../services/authentication-service/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild("domainAutoFocus") domainElementRef: ElementRef;
  @ViewChild("passAutoFocus") passElementRef: ElementRef;

  posDomain: string;
  username: string;
  password: string = '';
  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';

  constructor(
    private _httpService: HttpService, 
    private toastr: ToastrService, 
    private auth: AuthenticationService,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef
    ) {
  }

  ngOnInit(): void {
    //change background color to light blue
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#edefff';
  }
  
  ngAfterViewInit() {
    this.initLoginForm();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'white';
  }

  initLoginForm() {
    let cookiesExist = this.auth.isLoginFormExist();
    this.setAutoFocusDomain();
    if (cookiesExist) {
      this.username = this.auth.getUsername();
      this.posDomain = this.auth.getDomain();
      this.setAutoFocusPass();
    }
  }

  setAutoFocusDomain() {
    this.domainElementRef.nativeElement.focus();
  }

  setAutoFocusPass() {
    this.passElementRef.nativeElement.focus();
  }

  //Function: hideShowPassword() will toggle the password visibility with the eye icon
  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  login(): void {
    var obj = {
      client_id: this.username,
      client_secret: this.password,
      Host: this.posDomain
    }

    this._httpService.post<any>("/api/Token/Login", JSON.stringify(obj)).subscribe(data => {
      if (data.access_token != null || data.access_token != undefined) {
        let token = data.access_token;
        let expiredAt = data.expiredAt;

        //Set to localstorage
        localStorage.setItem("Token", token);
        localStorage.setItem("ExpireDate", expiredAt);
        this.auth.saveLoginForm(this.posDomain, this.username);

        let decoded = jwt_decode(token);
        let role = decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"].find(x => x == "KitD");
          
        if(role == "KitD"){
          this.auth.login("/pages");
        }
        else{
          this.toastr.error('Please check your role', 'Error');
        }
      }
    }, err => {
      console.error(err);
    })
  }
  
}