import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalMaterialConfig } from '../modal-material/modal-material-config';

@Component({
  selector: 'app-modal-confirm-update',
  templateUrl: './modal-confirm-update.component.html',
  styleUrls: ['./modal-confirm-update.component.scss']
})
export class ModalConfirmUpdateComponent implements OnInit {
  public config: ModalMaterialConfig;

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmUpdateComponent>
  ) {
    this.config = new ModalMaterialConfig();
    this.config.title = "There is a new update available";
    this.config.hideMoreButton = true;
  }

  ngOnInit() {
  }

  public onOk() {
    document.location.reload();
  }

}
