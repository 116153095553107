import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private _router: Router, private toaster: ToastrService) { }

  login(redirectURL: string): void {
    this._router.navigate([redirectURL]);
  }

  logout(): void {
    this._router.navigate(['']);
  }

  isAuthentication(): boolean {
    return localStorage.getItem('Token') != null && !this.isTokenExpired();
  }

  isTokenExpired(): boolean {
    const expireDate = new Date(localStorage.getItem('ExpireDate'));
    if (expireDate.getTime() - new Date().getTime() > 0){
      return false;
    }
    return true;
  }

  saveLoginForm(domain: string, username: string): void {
    localStorage.setItem('Domain', domain);
    localStorage.setItem('Username', username);
  }

  getUsername(): string {
    let username = localStorage.getItem('Username');
    return username;
  }

  getDomain(): string {
    let domain = localStorage.getItem('Domain');
    return domain;
  }

  isLoginFormExist(): boolean {
    let isDomainExist = this.getDomain();
    let isUsernameExist = this.getUsername();

    let retVal = false
    if (isDomainExist && isUsernameExist) {
      retVal = true;
    }
    return retVal;
  }

}
