import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { ModalConfirmUpdateComponent } from './shared/components/modal-confirm-update/modal-confirm-update.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    @Inject(SwUpdate) swUpdate: SwUpdate,
    private matDialog: MatDialog
  ) {
    swUpdate.versionUpdates.subscribe((event) => {
      swUpdate.activateUpdate().then((result: boolean) => {
        if (result) {
          this.updateApp(event);
        }
      });
    });
  }

  private updateApp(event: VersionEvent) {
    if (event.type == 'VERSION_READY') {
      this.matDialog.open(ModalConfirmUpdateComponent, { disableClose: true });
    }
  }
}
