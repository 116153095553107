<div (keyup)="onKeyup($event)" class="modal-material d-flex align-items-start flex-column position-relative maxHeight"
  [style.width]="config?.customWidth"
  [ngClass]="{ bodyScrollable: config?.bodyScrollable, hideScrollbar: config?.hideScrollbar }">
  <div mat-dialog-title class="d-flex w-100 justify-content-between modal-header position-relative {{
      background
    }}">
    <div class="back-button position-absolute pointer" (click)="onBack.emit()" *ngIf="config?.eMoney">
      <a class="btn back-button"><i class="fas fa-arrowfloat-start"></i></a>
    </div>
    <div class="d-flex flex-row mt-1" [ngClass]="{ 'mx-auto': config?.eMoney }">
      <h4 class="fw-normal">{{ config?.title }}</h4>
      <ng-content select="header"></ng-content>
    </div>
    <div *ngIf="!config.hideClose">
      <a class="btn-light-blue btn-sm pointer close" (click)="onCancel.emit()">
        <i class="fa-solid fa-xmark fa-lg text-primary"></i>
      </a>
    </div>
  </div>
  <mat-dialog-content class="modal-body w-100" [ngClass]="{ 'show-footer': !config?.hideFooter }">
    <ng-content select="content"></ng-content>

    <ng-content select="more-content" *ngIf="more"></ng-content>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="!config?.hideFooter" class="w-100 py-7 border-top d-flex justify-content-end">
    <a *ngIf="!config?.hideMoreButton" class="more clearfix" (click)="more = !more">More</a>
    <ng-content select="footer"></ng-content>
    <a *ngIf="!config?.hideCancel" class="btn btn-light-blue text-primary btn-lg fw-bold mx-3"
      (click)="onCancel.emit()">
      {{ config?.cancelText ? config?.cancelText : "Cancel" }}</a>

    <button *ngIf="!config?.hideOk" class="btn btn-light-blue me-6 text-primary btn-lg fw-bold mx-3"
      [disabled]="!config?.validated" (click)="onOk.emit()">
      {{ config?.okText ? config?.okText : "Ok (F2)" }}
    </button>
  </mat-dialog-actions>
</div>