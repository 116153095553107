import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { HeaderType } from "../../enums/header-type.enum";
import { ModalMaterialConfig } from "./modal-material-config";

@Component({
  selector: "modal-material",
  templateUrl: "./modal-material.component.html",
  styleUrls: ["./modal-material.component.scss"],
})
export class ModalMaterialComponent implements OnInit {
  @Input() config? : ModalMaterialConfig;

  @Output() onOk = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();
  @Output() onBack = new EventEmitter<any>();
  @Output() onShowMore = new EventEmitter<boolean>();

  term = "";
  background: string = "";
  more = false;

  ngOnInit(): void {
    if (this.config == null){
      this.config = new ModalMaterialConfig();
    }
    
    switch (this.config.headerType) {
      case HeaderType.Success:
        this.background = "bg-success";
        break;
      case HeaderType.Warning:
        this.background = "bg-warning";
        break;
      case HeaderType.Info:
        this.background = "bg-warning";
        break;
      case HeaderType.Error:
        this.background = "bg-danger";
        break;
      default:
        this.background = "";
        break;
    }
  }

  onKeyup(event: KeyboardEvent): void {
    if (this.config) {
      if (!this.config.disableKeyup) {
        if (event.code == "F2") {
          this.onOk.emit();
        }
      }
    }
  }
}
